import MorganLogoTwo from "./MorganLogoTwo.jpeg";
import Instagram_circle from "./Instagram_circle.png";
import facebook from "./facebook.png";
import tiktok from "./tiktok.png";
import NFT from "./blueDiamond.png";
import React, {
	Component,
} from "react";
import "./App.css";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import Contact from "./Components/Contact";
import {
	Switch,
	Route,
} from "react-router-dom";
import Home from "./Components/Home";
import Products from "./Components/Products";
import PaintParty from "./Components/PaintParty";
import ShoppingCart from "./Components/ShoppingCart";
import Subscribe from "./Components/MailChimp/Subscribe";
import Notification from "./Components/MailChimp/Notification";
import check from "./check.png";
import exclamation from "./Exclamation.png";
import xmark from "./Xmark.png";
import Blog from "./Components/Blog/Blog";
import Who from "./Components/Blog/Blogs/Who";
import Paracord from "./Components/Paracord/Paracords";

import { Button } from "bootstrap";
import ParacordBracelets from "./Components/Paracord/ParacordBracelets";
import Bracelets from "./Components/Paracord/Bracelets";
import Widget from "./Components/Paracord/Widget";
import NavArt from "./galleryArt.jpg";
import Stripe from "./Components/NewProducts/Stripe";
import Stripe_Subscription from "./Components/Stripe Subscription/Stripe_Subscription";
import FineSilver from "./Components/FineSilver/FineSilver";
import Jewelry from "./Components/JewelryClass";
import MixedEvent from "./Components/MixedEvent";

class App extends Component {
	constructor(
		props
	) {
		super(
			props
		);
		this.state =
			{
				subscribe:
					{
						placeholder:
							"Enter Email Address to Subscribe",
						buttonText:
							"Submit",
					},
				notification:
					{
						src: "",
						alt: "",
						message:
							"",
						level:
							"",
						visible: false,
					},
			};
	}

	configureNotification =
		(
			level
		) => {
			const notification =
				{
					...this
						.state
						.notification,
				};
			notification.level =
				level;
			if (
				level ===
				"success"
			) {
				notification.src =
					check;
				notification.alt =
					"Check Mark";
				notification.message = `Thank you for subscribing to our mailing list. You will recieve a welcome email shortly.`;
			} else if (
				level ===
				"warning"
			) {
				notification.src =
					exclamation;
				notification.alt =
					"Exlamation Point";
				notification.message = `The email you entered is already on our mailing list. Thank you for joining the community!`;
			} else {
				notification.src =
					xmark;
				notification.alt =
					"X Mark";
				notification.message = `There was an issue with your email submission. Please check  your email and try again.`;
			}
			this.setState(
				{
					notification,
				}
			);
		};

	showNotification =
		() => {
			const notification =
				{
					...this
						.state
						.notification,
				};
			notification.visible = true;
			this.setState(
				{
					notification,
				},
				() => {
					setTimeout(
						() => {
							notification.visible = false;
							this.setState(
								{
									notification,
								}
							);
						},
						3000
					);
				}
			);
		};

	render() {
		const {
			subscribe,
			notification,
		} =
			this
				.state;
		const paracordComponent =
			(
				<Paracord />
			);

		return (
			<Container
				className="App"
				fluid>
				<Navbar
					collapseOnSelect
					expand="lg"
					// bg="dark"
					variant="dark"
					fixed="top"
					fluid
					style={{
						color:
							"aqua",
						backgroundColor:
							"rgba(255, 122, 214, 0.8)",
					}}>
					<Navbar.Brand
						href="/"
						style={{
							marginRight:
								"5%",
						}}>
						<div
							style={{
								display:
									"flex",
							}}>
							<div
								style={{
									margin:
										"2%",
								}}>
								<Image
									alt="logo"
									src={
										MorganLogoTwo
									}
									width="60"
									height="60"
									className="d-inline-block align-top"
									roundedCircle
								/>{" "}
							</div>
							<div
								className="Name"
								style={{
									alignSelf:
										"center",
									color:
										"white",
									fontFamily:
										"fantasy",
									fontWeight:
										"bolder",
									fontSize:
										"12px",
									color:
										"aquamarine",
								}}>
								Morgan
								Dalia
								Peterson
							</div>
						</div>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse
						style={{
							color:
								"white",
						}}
						id="responsive-navbar-nav">
						<Nav className="me-auto">
							<NavDropdown
								title="Woven Jewelry"
								color="white">
								<NavDropdown.Item
									href="/FineSilver"
									style={{
										color:
											"navy",
									}}>
									Fine
									Silver
								</NavDropdown.Item>
								<NavDropdown.Item
									href="/paracordBracelet"
									style={{
										color:
											"navy",
									}}>
									Paracord
									Bracelets
								</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown
								title="Self Care Herbal Product Line"
								color="white">
								<NavDropdown.Item
									href="/herb"
									style={{
										color:
											"navy",
									}}>
									Individual
									Herbal
									Products
								</NavDropdown.Item>

								<NavDropdown.Divider />
								<NavDropdown.Item
									href="subscription"
									style={{
										color:
											"navy",
									}}>
									Herbal
									Self
									Care
									Subscription
									Box
								</NavDropdown.Item>
							</NavDropdown>

							{/* <NavDropdown
                title="Events"
                id="collasible-nav-dropdown "
                style={{ color: "navy" }}
              >
                <NavDropdown.Item href="/paintparty" style={{ color: "navy" }}>
                  Paint and Sip
                </NavDropdown.Item>
              </NavDropdown> */}

							{/* <Nav.Link href="/herb" style={{ color: "white" }}>
               Herbal Product Line
              </Nav.Link> */}
							<Nav.Link
								href="https://us1.list-manage.com/contact-form?u=763b3b58965fc975b2168f217&form_id=9c0aa7705ae395e015564e8689d727db

"
								style={{
									color:
										"white",
								}}>
								Contact
								Us
							</Nav.Link>

							<NavDropdown title="Services">
								<NavDropdown.Item
									href="/paintparty"
									style={{
										color:
											"navy",
									}}>
									Paint
									Party
								</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item
									href="/jewelry"
									style={{
										color:
											"navy",
									}}>
									Classic Loop-in-Loop Jewelry Making Class
								</NavDropdown.Item>

								<NavDropdown.Divider />
								<NavDropdown.Item
									href="https://www.fiverr.com/share/5A74db"
									style={{
										color:
											"navy",
									}}>
									Ecommerce
									Website
									Development
								</NavDropdown.Item>
							</NavDropdown>

							<NavDropdown
								title="Events"
								id="collasible-nav-dropdown "
								style={{
									color:
										"navy",
								}}>
								<NavDropdown.Item
									href="/paintparty"
									style={{
										color:
											"navy",
									}}>
									Paint
									and
									Sip
								</NavDropdown.Item>
<NavDropdown.Divider />
								<NavDropdown.Item
									href="/BrunchParty"
									style={{
										color:
											"navy",
									}}>
									Brunch Party
								</NavDropdown.Item>
							</NavDropdown>
						</Nav>

						<Nav.Link
							style={{
								marginRight:
									"5%",
							}}
							href="https://opensea.io/collection/treasures-of-pandora">
							<Image
								alt="NFT"
								src={
									NFT
								}
								width="25"
								height="25"
								className="d-inline-block align-top"
								roundedCircle
							/>{" "}
							<div
								style={{
									display:
										"flex",
									alignSelf:
										"center",
								}}>
								<h9
									style={{
										alignSelf:
											"center",
										color:
											"aqua",
									}}>
									NFT
								</h9>
							</div>
						</Nav.Link>

						<Nav
							style={{
								marginRight:
									"5%",
							}}>
							<Nav.Link
								style={{
									color:
										"white",
								}}
								href="https://www.amazon.com/s?rh=n%3A7141123011%2Cp_4%3AMorgan+Dalia+Peterson&ref=bl_sl_s_ap_web_7141123011">
								Merch
							</Nav.Link>
							<Nav.Link
								style={{
									color:
										"white",
								}}
								eventKey={
									2
								}
								href="/Blog">
								Blog
							</Nav.Link>

							<ShoppingCart />
						</Nav>
					</Navbar.Collapse>
				</Navbar>

				<Switch>
					<Route
						exact
						path="/"
						component={
							Home
						}
					/>
					<Route
						path="/FineSilver"
						component={
							FineSilver
						}
					/>
					<Route
						path="/paracordBracelet"
						component={
							Widget
						}
					/>
					<Route
						path="/herb"
						component={
							Stripe
						}
					/>
					<Route
						path="/subscription"
						component={
							Stripe_Subscription
						}
					/>
					<Route
						path="/Contact"
						component={
							Contact
						}
					/>
					<Route
						path="/products"
						component={
							Products
						}
					/>
					<Route
						path="/paintparty"
						component={
							PaintParty
						}
					/>
					<Route
						path="/BrunchParty"
						component={
							MixedEvent
						}
					/>

					<Route
						path="/jewelry"
						component={
							Jewelry
						}
					/>
					<Route
						path="/Blog"
						component={
							Blog
						}
					/>
					<Route
						path="/Who"
						component={
							Who
						}
					/>
				</Switch>

				{/* Newsletter form and styling */}
				{/* adding this text IN ORDER TO PUSH CHANGES to main heroku deployment to create changes in the to keep on addig things to this line*/}
				<div
					style={{
						flexFlow:
							"columnWrap",
						justifyContent:
							"center",
						alignItems:
							"center",
						alignSelf:
							"center",
					}}>
					{/* <Notification
            src={notification.src}
            alt={notification.alt}
            message={notification.message}
            level={notification.level}
            visible={notification.visible}
          />
          <Subscribe
            placeholder={subscribe.placeholder}
            buttonText={subscribe.buttonText}
            showNotification={this.showNotification}
            configureNotification={this.configureNotification}
          /> */}
				</div>

				<Navbar
					expand="lg"
					//bg="light"
					// variant="light"
					fixed="bottom"
					fluid
					style={{
						backgroundColor:
							"rgba(255, 122, 214, 0.8)",
						textAlign:
							"center",
						color:
							"white",
					}}>
					<div
						class="container-fluid"
						style={{
							justifyContent:
								"center",
							display:
								"flex",
						}}>
						<Nav.Link
							style={{
								marginRight:
									"5%",
							}}
							href="https://opensea.io/collection/treasures-of-pandora">
							<Image
								alt="NFT"
								src={
									NFT
								}
								width="35"
								height="35"
								className="d-inline-block align-top"
								roundedCircle
							/>{" "}
							<div
								style={{
									display:
										"flex",
									alignSelf:
										"center",
								}}>
								<h9
									style={{
										alignSelf:
											"center",
										color:
											"aqua",
									}}>
									NFT
								</h9>
							</div>
						</Nav.Link>
						<Nav.Link
							style={{
								marginRight:
									"5%",
							}}
							href="https://business.facebook.com/MorganDaliaPeterson">
							<Image
								alt="logo"
								src={
									facebook
								}
								width="35"
								height="35"
								className="d-inline-block align-top"
								roundedCircle
							/>{" "}
						</Nav.Link>
						<Nav.Link
							style={{
								marginRight:
									"5%",
							}}
							href="https://www.instagram.com/morena_latina/">
							<Image
								alt="logo"
								src={
									Instagram_circle
								}
								width="35"
								height="35"
								className="d-inline-block align-top"
								roundedCircle
							/>{" "}
						</Nav.Link>
						<Nav.Link
							style={{
								marginRight:
									"5%",
							}}
							href="https://www.tiktok.com/@official_morena_latina">
							<Image
								alt="logo"
								src={
									tiktok
								}
								width="35"
								height="35"
								className="d-inline-block align-top"
								roundedCircle
							/>{" "}
						</Nav.Link>
						<div>
							<h6
								style={{
									color:
										"aqua",
								}}>
								©2024
								All
								Rights
								Reserved
								by
								Morgan
								Dalia
								Peterson
							</h6>
						</div>
					</div>
				</Navbar>
			</Container>
		);
	}
}
export default App;
