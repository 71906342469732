import React, { Component } from "react";
import PaintPartyEventButton from "./PaintPartyEventButton";
import event from "../ImagesFolder/mixedevent.jpeg";
import Card from "react-bootstrap/Card";
import "../App.css";
export class MixedEvent extends Component {
  render() {
    return (
      <div className="Home">
        <div className="Jewelry" style={{ display: "flex", justifyContent: "center",margin:"20%" }}>
          <Card className="JewelryCard" style={{ width: "auto", height: "auto", opacity: "90%" , marginBottom:"10%"}}>
            <Card.Img variant="top" src={event} rounded />
            <Card.Body>
              <Card.Title>Brunch Party</Card.Title>
              <Card.Text>
                <div  style={{ marginBottom: "20" }}>
                  <p>
                    
At our vibrant brunch party, guests can choose between mixing unique cocktails in a fun mixology workshop, creating colorful art in a painting session, crafting jewelry, or indulging in a soothing Thai massage.
                  </p>
                  <p>
                    3pm - 6pm 5508 Rozzelles Ferry Road Charlotte,NC 28214
                                </p>
                                <p> </p>
                                
                                

                      <stripe-buy-button
  buy-button-id="buy_btn_1QPWBGLtyWpTzVZrrzLVE8Ke"
  publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"
>
</stripe-buy-button>
                                


                </div>
                
              </Card.Text>
            </Card.Body>
                </Card >
                <div className="Jewelry" style={{ display: "flex", justifyContent: "center", justifyContent: "space-around", marginBottom: "20%"}}>

                


                    </div>
          
        </div>
      </div>
    );
  }
}

export default MixedEvent;
