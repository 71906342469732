import React, { Component } from "react";

export class PaintPartyEventButton extends Component {
  render() {
    return (
      <div>
        <script async
  src="https://js.stripe.com/v3/buy-button.js">
</script>

<stripe-buy-button
  buy-button-id="buy_btn_1QNyHPLtyWpTzVZrKfcbh7VT"
  publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"
>
</stripe-buy-button>
      </div>
    );
  }
}

export default PaintPartyEventButton;
