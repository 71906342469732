import React, { Component } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import IMG_0256 from "../IMG_0256.jpeg";
import PayPalButton from "./PayPalButton";
import ButtonTwo from "./ButtonTwo";
import ButtonThree from "./ButtonThree";
import ButtonFour from "./ButtonFour";
import ButtonFive from "./ButtonFive";
import ButtonSix from "./ButtonSix";
import ButtonSeven from "./ButtonSeven";
import ButtonEight from "./ButtonEight";
import MagicalUsButton from "./MagicalUsButton";
import Card from "react-bootstrap/Card";
import EveningSunButton from "../Carousel/EveningSunButton";
import EveningSunCarousel from "../Carousel/EveningSunCarousel";
import MagicalUsCarousel from "../Carousel/MagicalUsCarousel";
import MagicalCarousel from "../Carousel/MagicalCarousel";
import PurleHazeCarousel from "../Carousel/PurleHazeCarousel";
import DrowninCarousel from "../Carousel/DrowninCarousel";
import LadyCarousel from "../Carousel/LadyCarousel";
import PapiPacifyCarousel from "../Carousel/PapiPacifyCarousel";
import BlackUsCarousel from "../Carousel/BlackUsCarousel";
import DesperadoCarousel from "../Carousel/DesperadoCarousel";
import PrideButton from "./PrideButton";
import PrideCarousel from "../Carousel/PrideCarousel";
import ThumbelinaCarousel from "../Carousel/ThumbelinaCarousel";
import ThumbelinaButton from "./ThumbelinaButton";
import Button from "react-bootstrap/esm/Button";
import Jumbotron from "react-bootstrap/esm/Jumbotron";
import { Church } from "../Carousel/Church";
import ChurchButton from "../Carousel/ChurchButton";
import Tribal from "../Carousel/Tribal";
import TribalButton from "../Carousel/TribalButton";
import UnchainedButton from "../Carousel/UnchainedButton";
import Unchained from "../Carousel/Unchained";
import Trumpet from "../Carousel/Trumpet";
import TrumpetButton from "../Carousel/TrumpetButton";

import Balloon from "../ImagesFolder/BalloonHead.jpeg";
import BigHat from "../ImagesFolder/BigHat.jpeg";
import Bigi from "../ImagesFolder/Bigi.jpeg";
import Bigi2 from "../ImagesFolder/Bigi2.jpeg";
import BlueMood from "../ImagesFolder/bluemood.jpeg";
import Braid from "../ImagesFolder/Braid.jpeg";
import BrightBoy from "../ImagesFolder/BrightBoy.jpeg";
import BrightEyes from "../ImagesFolder/BrightEyes.jpeg";
import BrownSugar from "../ImagesFolder/brownSugar.jpeg";
import ColorGuy from "../ImagesFolder/ColorGuy.jpeg";
import Gold from "../ImagesFolder/goldHAt.jpeg";
import Locs from "../ImagesFolder/locs.jpeg"; 
import OldMAn from "../ImagesFolder/oldman.jpeg";
import PinkEye from "../ImagesFolder/pinkeye.jpeg";
import PinkGog from "../ImagesFolder/PinkGoggles.jpeg";
import Redd from "../ImagesFolder/redd.jpeg";
import RedGuy from "../ImagesFolder/redGuy.jpeg";
import Sade from "../ImagesFolder/Sade.jpeg";
import scream from "../ImagesFolder/scream.jpeg";
import Spotted from "../ImagesFolder/Spotted.jpeg";
import Sunny from "../ImagesFolder/Sunny.jpeg";
import Throat from "../ImagesFolder/Throat.jpeg";
import UnderWater from "../ImagesFolder/UnderWater.jpeg";
import Universal from "../ImagesFolder/universal.jpeg";
import Yoga from "../ImagesFolder/yoga.jpeg";
export class Products extends Component {
  render() {
    return (
      <div className="Home">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // eslint-disable-next-line
            justifyContent: "space-around",
            alignContent: "space-between",
            flexWrap: "wrap",
          }}
        >

<Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <div className="jumbotron jumbotron-fluid">
              <div className="container">
                <Jumbotron  style = {{margin:"5%"}}>
  
    <img
      className="d-block w-100"
      src={Balloon}
      alt="First slide"
    />
    </Jumbotron>
              </div>
            </div>
            <Card.Body>
              <Card.Title>Balloon Head</Card.Title>
              <h5>Size</h5>
               <h6>8" x 10" $55</h6>
              
              <Card.Text>

<stripe-buy-button
  buy-button-id="buy_btn_1QN0CiLtyWpTzVZr8fawvWsn"
  publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"
>
</stripe-buy-button>
              </Card.Text>
            </Card.Body>
          </Card>


          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <div className="jumbotron jumbotron-fluid">
              <div className="container">
                <Jumbotron  style = {{margin:"5%"}}>
  
    <img
      className="d-block w-100"
      src={BigHat}
      alt="First slide"
    />
    </Jumbotron>
              </div>
            </div>
            <Card.Body>
              <Card.Title>Big Hat</Card.Title>
              <h5>Size</h5>
               <h6>18" x 24" $333</h6>
              
              <Card.Text>
                
<stripe-buy-button
  buy-button-id="buy_btn_1QN1KjLtyWpTzVZrgtFOlqFE"
  publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"
>
</stripe-buy-button>
              </Card.Text>
            </Card.Body>
          </Card>


          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <div className="jumbotron jumbotron-fluid">
              <div className="container">
                <Jumbotron  style = {{margin:"5%"}}>
  
    <img
      className="d-block w-100"
      src={Gold}
      alt="First slide"
    />
    </Jumbotron>
              </div>
            </div>
            <Card.Body>
              <Card.Title>Goldy</Card.Title>
              <h5>Size</h5>
               <h6>18" x 24" $333</h6>
              
              <Card.Text>
                

<stripe-buy-button
  buy-button-id="buy_btn_1QN1ORLtyWpTzVZrai8Sz1AZ"
  publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"
>
</stripe-buy-button>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <div className="jumbotron jumbotron-fluid">
              <div className="container">
                <Jumbotron  style = {{margin:"5%"}}>
  
    <img
      className="d-block w-100"
      src={Bigi2}
      alt="First slide"
    />
    </Jumbotron>
              </div>
            </div>
            <Card.Body>
              <Card.Title>Bigi Too</Card.Title>
              <h5>Size</h5>
               <h6>18" x 24" $333</h6>
              
              <Card.Text>
                

<stripe-buy-button
  buy-button-id="buy_btn_1QN1QoLtyWpTzVZrxbcJ06PF"
  publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"
>
</stripe-buy-button>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <div className="jumbotron jumbotron-fluid">
              <div className="container">
                <Jumbotron  style = {{margin:"5%"}}>
  
    <img
      className="d-block w-100"
      src={BlueMood}
      alt="First slide"
    />
    </Jumbotron>
              </div>
            </div>
            <Card.Body>
              <Card.Title>Blue Mood</Card.Title>
              <h5>Size</h5>
               <h6>8" x 10" $55</h6>
              
              <Card.Text>
                <script async
  src="https://js.stripe.com/v3/buy-button.js">
</script>

<stripe-buy-button
  buy-button-id="buy_btn_1QNIIGLtyWpTzVZr2kXnYJvo"
  publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"
>
</stripe-buy-button>
              </Card.Text>
            </Card.Body>
          </Card>


          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <div className="jumbotron jumbotron-fluid">
              <div className="container">
                <Jumbotron  style = {{margin:"5%"}}>
  
    <img
      className="d-block w-100"
      src={Braid}
      alt="First slide"
    />
    </Jumbotron>
              </div>
            </div>
            <Card.Body>
              <Card.Title>Braided</Card.Title>
              <h5>Size</h5>
               <h6>18" x 24" $333</h6>
              
              <Card.Text>
                <script async
  src="https://js.stripe.com/v3/buy-button.js">
</script>

<stripe-buy-button
  buy-button-id="buy_btn_1QNIMXLtyWpTzVZr47LQgd65"
  publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"
>
</stripe-buy-button>
              </Card.Text>
            </Card.Body>
          </Card>


          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <div className="jumbotron jumbotron-fluid">
              <div className="container">
                <Jumbotron  style = {{margin:"5%"}}>
  
    <img
      className="d-block w-100"
      src={BrightBoy}
      alt="First slide"
    />
    </Jumbotron>
              </div>
            </div>
            <Card.Body>
              <Card.Title>Bright Boy</Card.Title>
              <h5>Size</h5>
               <h6>8" x 10" $55</h6>
              
              <Card.Text>
               <script async
  src="https://js.stripe.com/v3/buy-button.js">
</script>

<stripe-buy-button
  buy-button-id="buy_btn_1QNISRLtyWpTzVZrVeDIMB3z"
  publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"
>
</stripe-buy-button>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <div className="jumbotron jumbotron-fluid">
              <div className="container">
                <Jumbotron  style = {{margin:"5%"}}>
  
    <img
      className="d-block w-100"
      src={BrightEyes}
      alt="First slide"
    />
    </Jumbotron>
              </div>
            </div>
            <Card.Body>
              <Card.Title>Bright Eyes</Card.Title>
              <h5>Size</h5>
               <h6>8" x 10" $55</h6>
              
              <Card.Text>
              <script async
  src="https://js.stripe.com/v3/buy-button.js">
</script>

<stripe-buy-button
  buy-button-id="buy_btn_1QNIVWLtyWpTzVZrwC5rDQjl"
  publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"
>
</stripe-buy-button>
              </Card.Text>
            </Card.Body>
          </Card>


          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <div className="jumbotron jumbotron-fluid">
              <div className="container">
                <Jumbotron  style = {{margin:"5%"}}>
  
    <img
      className="d-block w-100"
      src={BrownSugar}
      alt="First slide"
    />
    </Jumbotron>
              </div>
            </div>
            <Card.Body>
              <Card.Title>Brown Sugar</Card.Title>
              <h5>Size</h5>
               <h6>8" x 10" $55</h6>
              
              <Card.Text>
                <script async
  src="https://js.stripe.com/v3/buy-button.js">
</script>

<stripe-buy-button
  buy-button-id="buy_btn_1QNIY7LtyWpTzVZrWPwrj4Ls"
  publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"
>
</stripe-buy-button>
              </Card.Text>
            </Card.Body>
          </Card>


          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <div className="jumbotron jumbotron-fluid">
              <div className="container">
                <Jumbotron  style = {{margin:"5%"}}>
  
    <img
      className="d-block w-100"
      src={ColorGuy}
      alt="First slide"
    />
    </Jumbotron>
              </div>
            </div>
            <Card.Body>
              <Card.Title>Color Forms</Card.Title>
              <h5>Size</h5>
               <h6>8" x 10" $55</h6>
              
              <Card.Text>
                <script async
  src="https://js.stripe.com/v3/buy-button.js">
</script>

<stripe-buy-button
  buy-button-id="buy_btn_1QNIaaLtyWpTzVZreecTcCB6"
  publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"
>
</stripe-buy-button>
              </Card.Text>
            </Card.Body>
          </Card>



          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <div className="jumbotron jumbotron-fluid">
              <div className="container">
                <Jumbotron  style = {{margin:"5%"}}>
  
    <img
      className="d-block w-100"
      src={Bigi}
      alt="First slide"
    />
    </Jumbotron>
              </div>
            </div>
            <Card.Body>
              <Card.Title>Bigi</Card.Title>
              <h5>Size</h5>
               <h6>8" x 10" $55</h6>
              
              <Card.Text>
                
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <div className="jumbotron jumbotron-fluid">
              <div className="container">
                <Jumbotron  style = {{margin:"5%"}}>
  
    <img
      className="d-block w-100"
      src={Locs}
      alt="First slide"
    />
    </Jumbotron>
              </div>
            </div>
            <Card.Body>
              <Card.Title>Locd</Card.Title>
              <h5>Size</h5>
               <h6>8" x 10" $55</h6>
              
              <Card.Text>
                <script async
  src="https://js.stripe.com/v3/buy-button.js">
</script>

<stripe-buy-button
  buy-button-id="buy_btn_1QNIhaLtyWpTzVZrrwGLJZ3v"
  publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"
>
</stripe-buy-button>
              </Card.Text>
            </Card.Body>
          </Card>



  <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <div className="jumbotron jumbotron-fluid">
              <div className="container">
                <Jumbotron  style = {{margin:"5%"}}>
  
    <img
      className="d-block w-100"
      src={Sade}
      alt="First slide"
    />
    </Jumbotron>
              </div>
            </div>
            <Card.Body>
              <Card.Title>Sade</Card.Title>
              <h5>Size</h5>
               <h6>18" x 24" $444</h6>
              
              <Card.Text>
               <script async
  src="https://js.stripe.com/v3/buy-button.js">
</script>

<stripe-buy-button
  buy-button-id="buy_btn_1QNIlVLtyWpTzVZrjrswKCxd"
  publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"
>
</stripe-buy-button>

              </Card.Text>
            </Card.Body>
          </Card>


































          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <div className="jumbotron jumbotron-fluid">
              <div className="container">
                <PrideCarousel />
              </div>
            </div>
            <Card.Body>
              <Card.Title>Self Love</Card.Title>
              <h5>Size</h5>
               <h6>30" x 40" $1099.99</h6>
              
              <Card.Text>
                <PrideButton />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <EveningSunCarousel />
            <Card.Body>
              <Card.Title>Dog Hat</Card.Title>
              <h5>Size</h5>
               <h6>20" x 24" $500</h6>
              <Card.Text>
                <EveningSunButton />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <MagicalUsCarousel />
            <Card.Body>
              <Card.Title>Puerto Rico</Card.Title>
              <h5>Size</h5>
               <h6>25" x 34" $1099.99</h6>
              <Card.Text>
                <MagicalUsButton />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <Church />
            <Card.Body>
              <Card.Title>Church Hat</Card.Title>
              <h5>Size</h5>
               <h6>20" x 24" $500.99</h6>
              <Card.Text>
                <ChurchButton />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <Tribal/>
            <Card.Body>
              <Card.Title>Tribal</Card.Title>
              <h5>Size</h5>
               <h6>20" x 24" $500.99</h6>
              <Card.Text>
                <TribalButton/>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <Unchained/>
            <Card.Body>
              <Card.Title>Unchained Melody</Card.Title>
              <h5>Size</h5>
               <h6>18" x 24" $299.99</h6>
              <Card.Text>
                <UnchainedButton/>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <Trumpet/>
            <Card.Body>
              <Card.Title>Trumpet Lady</Card.Title>
              <h5>Size</h5>
               <h6>18" x 24" $299.99</h6>
              <Card.Text>
                <TrumpetButton/>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <MagicalCarousel />
            <Card.Body>
              <Card.Title>Magical</Card.Title>
              <Card.Text>
                <PayPalButton />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            {/* <Card.Img variant="top" src={IMG_0197} rounded/> */}
            <PurleHazeCarousel />
            <Card.Body>
              <Card.Title>Purle Haze</Card.Title>
              <Card.Text>
                <ButtonTwo />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            {/* <Card.Img variant="top" src={IMG_0244} rounded/> */}
            <DrowninCarousel />
            <Card.Body>
              <Card.Title>Drownin</Card.Title>
              <Card.Text>
                <ButtonThree />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <Card.Img variant="top" src={IMG_0256} rounded />
            <Card.Body>
              <Card.Title>Breath</Card.Title>
              <Card.Text>
                <ButtonFour />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <LadyCarousel />
            <Card.Body>
              <Card.Title>Lady</Card.Title>
              <Card.Text>
                <ButtonFive />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <PapiPacifyCarousel />
            <Card.Body>
              <Card.Title>Papi Pacify</Card.Title>
              <Card.Text>
                <ButtonSix />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <BlackUsCarousel />
            <Card.Body>
              <Card.Title>Pa Ti Papi</Card.Title>
              <Card.Text>
                <ButtonSeven />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <DesperadoCarousel />
            <Card.Body>
              <Card.Title>Desperado</Card.Title>
              <Card.Text>
                <ButtonEight />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <ThumbelinaCarousel />
            <Card.Body>
              <Card.Title>Black Thumbelina</Card.Title>
              <Card.Text>
                <ThumbelinaButton />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}

export default Products;
