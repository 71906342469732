import React, { Component } from "react";
import PaintPartyEventButton from "./PaintPartyEventButton";
import jewelry from "../jewelry1.jpg";
import Card from "react-bootstrap/Card";
import "../App.css";
export class Jewelry extends Component {
  render() {
    return (
      <div className="Home">
        <div className="Jewelry" style={{ display: "flex", justifyContent: "space-evenly", }}>
          <Card className="JewelryCard" style={{ width: "35rem", height: "auto", opacity: "90%" , marginBottom:"10%"}}>
            <Card.Img variant="top" src={jewelry} rounded />
            <Card.Body>
              <Card.Title>Classic Loop-in-Loop Jewelry Making Class</Card.Title>
              <Card.Text>
                <div  style={{ marginBottom: "20" }}>
                  <p>
                    In a series of classes focused on classic loop-in-loop jewelry making, students can learn essential techniques for creating intricate chain designs. This includes mastering the art of forming loops, precise measurements, and selecting the right materials. Participants will gain hands-on experience in manipulating metal, using tools like pliers and cutters, torch, and understanding the importance of pattern and symmetry. Additionally, the class fosters creativity, allowing individuals to design unique pieces while developing problem-solving skills. Students will also explore the history and cultural significance of loop-in-loop jewelry, enriching their appreciation for this timeless craft. Overall, it's a blend of artistry and technique.

                  </p>
                  <p>
                    Classes held at 5508 Rozzelles Ferry Road Charlotte,NC 28214
                                </p>
                                <p>contact via email at mdp1313@yahoo.com </p>
                                
                                

                      
                                


                </div>
                
              </Card.Text>
            </Card.Body>
                </Card >
                <div className="Jewelry" style={{ display: "flex", justifyContent: "center", justifyContent: "space-around",flexDirection:"column", marginBottom: "20%"}}>

                <stripe-buy-button
  buy-button-id="buy_btn_1QLU8FLtyWpTzVZrK00jGhPH"
  publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"
>
</stripe-buy-button>
                
                <stripe-buy-button
  buy-button-id="buy_btn_1QLU5RLtyWpTzVZrTjmqh4I9"
  publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"
>
                    </stripe-buy-button>
                    </div>
          
        </div>
      </div>
    );
  }
}

export default Jewelry;
